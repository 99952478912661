export function siteMetadata(data) {
  return data?.site?.siteMetadata ?? {};
}

export function frontmatter(data) {
  return data?.markdownRemark?.frontmatter ?? {};
}

export function markdownRemark(data) {
  return data?.markdownRemark ?? {};
}
