import { graphql } from 'gatsby';
import { getSrc as getImgSrc } from 'gatsby-plugin-image';
import { frontmatter, siteMetadata } from '../helper/data';
import { stripTags, markdownToHtml } from '../helper/transformation';

export function mapToProps(data = {}, location = { pathname: 'not/defined' }) {
  const fmData = frontmatter(data);
  const smData = siteMetadata(data);
  const { pathname } = location;

  return {
    title: fmData.title,
    description: fmData.description,
    canonical: smData.siteUrl + pathname,
    openGraph: {
      title: fmData.title,
      description: fmData?.intro?.textMarkdown
        ? stripTags(markdownToHtml(fmData.intro.textMarkdown))
        : fmData?.description,
      images: fmData?.image?.file
        ? [
            {
              url: smData.siteUrl + getImgSrc(fmData.image.file),
              alt: fmData.image?.altText
            }
          ]
        : null
    }
  };
}

export const fragmentFrontmatter = graphql`
  fragment MetaFrontMatter on MarkdownRemarkFrontmatter {
    title
    description
    image {
      altText
      file {
        childImageSharp {
          gatsbyImageData(width: 1200, placeholder: NONE, layout: FIXED)
        }
      }
    }
    intro {
      heading
      textMarkdown
    }
  }
`;

export const fragmentSiteMeta = graphql`
  fragment MetaSiteMetadata on SiteSiteMetadata {
    siteUrl
  }
`;
