import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

const withCssBaseline = Component => props => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Component {...props} />
    </React.Fragment>
  );
};

export default withCssBaseline;
