import { Link as GatsbyLink } from 'gatsby';

export function isInternalTarget(to) {
  return /^\/(?!\/)/.test(to);
}

export function getLinkPropertiesForTarget(to) {
  if (isInternalTarget(to)) {
    return {
      component: GatsbyLink,
      to
    };
  }
  return {
    component: 'a',
    href: to,
    rel: 'noopener'
  };
}
