export function getProps() {
  return {
    serviceLinks: [
      { to: '/contact/', children: 'Contact' },
      { to: '/legal/', children: 'Legal' }
    ],
    subline:
      'Here is the Subline that can be long, or even longer if you want to see it multiline'
  };
}
