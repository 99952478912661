import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

/**
 * Implementation is an adaption of https://github.com/jonsuh/hamburgers
 **/

const useStyles = makeStyles(theme => {
  const menuStyle = {
    padding: [[23, 27, 25, 26]],
    layerWidth: 27,
    layerHeight: 3,
    layerSpacing: 5,
    radius: 2,
    colorIcon: `${theme.palette.text.light}`,
    colorBackground: `${theme.palette.primary.dark}`
  };
  const menuStyleLarge = {
    padding: [[29, 34, 35]],
    layerWidth: 36,
    layerHeight: 4,
    layerSpacing: 7,
    radius: 2
  };
  const totalLayerHeight =
    menuStyle.layerHeight * 3 + menuStyle.layerSpacing * 2;
  const totalLayerHeightLarge =
    menuStyleLarge.layerHeight * 3 + menuStyleLarge.layerSpacing * 2;

  return {
    root: {
      position: 'relative',
      margin: 0,
      padding: menuStyle.padding,
      display: 'inline-flex',
      cursor: 'pointer',

      transitionTimingFunction: 'linear',
      transitionDuration: '.15s',
      transitionProperty: 'opacity,filter',

      // normalize
      lineHeight: `${menuStyle.layerHeight * 3 + menuStyle.layerSpacing * 2}px`,
      font: 'inherit',
      color: 'inherit',
      textTransform: 'none',
      backgroundColor: menuStyle.colorBackground,
      border: 0,
      overflow: 'visible',

      '@media (hover: hover)': {
        '&:hover': {
          '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            top: 0,
            display: 'block',
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.action.hover
          }
        }
      },

      [theme.breakpoints.up('md')]: {
        padding: menuStyleLarge.padding,
        lineHeight: `${
          menuStyleLarge.layerHeight * 3 + menuStyleLarge.layerSpacing * 2
        }px`,

        '& $box': {
          margin: `${
            (menuStyleLarge.layerWidth -
              totalLayerHeightLarge -
              menuStyleLarge.layerHeight) /
            2
          }px 0`,
          top: `${menuStyleLarge.layerHeight / 2}px`,
          width: `${menuStyleLarge.layerWidth}px`,
          height: `${totalLayerHeightLarge}px`,
          lineHeight: `${totalLayerHeightLarge}px`
        },
        '& $inner': {
          marginTop: `${menuStyleLarge.layerHeight / -2}px`,

          '&, &::after, &::before': {
            width: `${menuStyleLarge.layerWidth}px`,
            height: `${menuStyleLarge.layerHeight}px`,
            borderRadius: `${menuStyleLarge.radius}px`
          },
          '&::before': {
            top: `${
              (menuStyleLarge.layerHeight + menuStyleLarge.layerSpacing) * -1
            }px`
          },

          '&::after': {
            bottom: `${
              (menuStyleLarge.layerHeight + menuStyleLarge.layerSpacing) * -1
            }px`
          }
        },

        '&$animation3dx': {
          '& $box': {
            perspective: `${menuStyleLarge.layerWidth * 2}px`
          },
          '&$isActive': {
            '& $inner': {
              '&::before': {
                transform: `translate3d(0, ${
                  menuStyleLarge.layerHeight + menuStyleLarge.layerSpacing
                }px, 0) rotate(45deg)`
              },
              '&::after': {
                transform: `translate3d(0, ${
                  (menuStyleLarge.layerHeight + menuStyleLarge.layerSpacing) *
                  -1
                }px, 0) rotate(-45deg)`
              }
            }
          }
        }
      }
    },
    pane: {
      position: 'absolute',
      backgroundColor: menuStyle.colorBackground
    },
    animation3dx: {
      '& $box': {
        perspective: `${menuStyle.layerWidth * 2}px`
      },

      '& $inner': {
        transition:
          'transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)',

        '&::before, &::after': {
          transition: 'transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1)'
        }
      },

      '&$isActive': {
        '& $inner': {
          backgroundColor: 'transparent !important',
          transform: 'rotateY(180deg)',

          '&::before': {
            transform: `translate3d(0, ${
              menuStyle.layerHeight + menuStyle.layerSpacing
            }px, 0) rotate(45deg)`
          },

          '&::after': {
            transform: `translate3d(0, ${
              (menuStyle.layerHeight + menuStyle.layerSpacing) * -1
            }px, 0) rotate(-45deg)`
          }
        }
      }
    },

    box: {
      position: 'relative',
      display: 'inline-block',
      margin: `${
        (menuStyle.layerWidth - totalLayerHeight - menuStyle.layerHeight) / 2
      }px 0`,
      top: 0,
      // top: `${menuStyle.layerHeight / 2}px`,
      width: `${menuStyle.layerWidth}px`,
      height: `${totalLayerHeight}px`,
      lineHeight: `${totalLayerHeight}px`
    },

    inner: {
      top: '50%',
      display: 'block',
      marginTop: `${menuStyle.layerHeight / -2}px`,
      backgroundColor: menuStyle.colorIcon,

      '&, &::after, &::before': {
        position: 'absolute',
        width: `${menuStyle.layerWidth}px`,
        height: `${menuStyle.layerHeight}px`,
        transitionTimingFunction: 'ease',
        transitionDuration: '.15s',
        transitionProperty: 'transform',
        borderRadius: `${menuStyle.radius}px`,
        backgroundColor: menuStyle.colorIcon
      },

      '&::before, &::after': {
        display: 'block',
        content: '""'
      },

      '&::before': {
        top: `${(menuStyle.layerHeight + menuStyle.layerSpacing) * -1}px`
      },

      '&::after': {
        bottom: `${(menuStyle.layerHeight + menuStyle.layerSpacing) * -1}px`
      }
    },
    isActive: {}
  };
});

const MenuButton = props => {
  const { ariaLabel, isActive, className, disableRipple, ...other } = props;
  const classes = useStyles(props);

  return (
    <ButtonBase
      className={clsx(
        classes.root,
        classes.animation3dx,
        {
          [classes.isActive]: isActive
        },
        className
      )}
      aria-label={ariaLabel}
      disableRipple={disableRipple}
      centerRipple
      {...other}
    >
      <span className={classes.box}>
        <span className={classes.inner}></span>
      </span>
    </ButtonBase>
  );
};

MenuButton.defaultProps = {
  ariaLabel: 'Menu Button',
  disableRipple: true
};

MenuButton.propTypes = {
  ariaLabel: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  disableRipple: PropTypes.bool,
  className: PropTypes.string
};

export default MenuButton;
