import createSvgIcon from '@material-ui/core/utils/createSvgIcon';
import { makeStyles } from '@material-ui/styles';
import { getDisplayName } from '../utils/helpers';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(36)
  },
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(24)
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(48)
  }
}));

const customSize = Component => props => {
  const classes = useStyles(props);

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `customSize(${getDisplayName(Component)})`;
  }

  return <Component {...props} classes={classes} />;
};
export default function createIcon(path, displayName) {
  return customSize(createSvgIcon(path, displayName));
}
