export const brightorange = {
  main: '#f77f35',
  light: '#ffb063',
  dark: '#be5000',
  contrastText: '#000'
};

export const darkblue = {
  main: '#1b1547',
  light: '#473b73',
  dark: '#000021',
  contrastText: '#fff'
};

export const strongred = {
  main: '#cd3132',
  light: '#ff665c',
  dark: '#95000b',
  contrastText: '#fff'
};

export const skyblue = {
  main: '#2d9cdb',
  light: '#85c6ea',
  dark: '#18628b',
  contrastText: '#104360'
};

export const grassgreen = {
  main: '#94cb20',
  light: '#bfe769',
  dark: '#547212',
  contrastText: '#000'
};

export default {
  brightorange,
  darkblue,
  strongred,
  skyblue,
  grassgreen
};
