import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../atoms/Logo';
import MainMenu from '../../organisms/MainMenue';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(4) + theme.spacing(5),
      '& $logo': {
        margin: [[theme.spacing(4), 0, 0, theme.spacing(5)]]
      }
    }
  },
  mainMenu: {},
  logo: {
    zIndex: theme.zIndex.modal + 1,
    margin: [[theme.spacing(2), 0, 0, theme.spacing(2)]]
  },
  light: {
    backgroundColor: theme.palette.background.default
  }
}));

function TopBar(props) {
  const { onHomePage, menueItems, className } = props;
  const classes = useStyles(props);

  return (
    <header
      className={clsx(
        classes.root,
        {
          [classes.light]: onHomePage
        },
        className
      )}
    >
      {onHomePage ? (
        <Logo component="h1" forceHiddenText={true} className={classes.logo} />
      ) : (
        <Logo
          className={classes.logo}
          component={GatsbyLink}
          to="/"
          onLight={false}
        />
      )}
      <MainMenu
        className={classes.mainMenu}
        items={menueItems}
        position="fixed"
      />
    </header>
  );
}

TopBar.defaultProps = {
  onHomePage: false,
  isLight: false
};

TopBar.propTypes = {
  onHomePage: PropTypes.bool,
  isLight: PropTypes.bool,
  menueItems: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default TopBar;
