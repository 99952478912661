import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: theme.breakpoints.values['lg'],
    margin: [[0, 'auto']],
    padding: 0
  }
}));

const Main = props => {
  const { children, className } = props;
  const classes = useStyles(props);

  return <main className={clsx(classes.root, className)}>{children}</main>;
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string
};

export default Main;
