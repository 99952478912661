import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import baseTheme from '../themes/base';

const withTheme = Component => props => {
  const { theme = baseTheme, ...otherProps } = props;

  return (
    <ThemeProvider theme={theme}>
      <Component {...otherProps} />
    </ThemeProvider>
  );
};

export default withTheme;
