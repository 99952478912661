import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from '../../../../utils/helpers';
import clsx from 'clsx';

/**
 * helper function for non css grid layouting
 * generates a calc string representing a colspan for 'numCol' columns
 *
 * @param theme {Object} theme instance
 * @param breakpoint {String} target breakpoint for calcualation
 * @param numCol {Number} of columns to span
 *
 * @return {String} with css calc term like 'calc(16.66%)'
 **/
export const gridSpanCalc = (
  theme,
  breakpoint = 'xs',
  numCol,
  operantsOnly = false
) => {
  const { columns } = theme.grid[breakpoint];
  const columnsWidth = `${(100 / columns) * numCol}%`;
  const correct = `${numCol * 2}px`;
  const operants = `${columnsWidth} + ${correct}`;

  if (operantsOnly) {
    return operants;
  }
  return `calc(${operants})`;
};

const useStyles = makeStyles(theme => {
  const mediaQuerySmall = {
    [theme.breakpoints.up('sm')]: {
      padding: [[0, theme.grid.sm.inset]],
      columnGap: theme.grid.sm.gap,
      gridTemplateColumns: `repeat(${theme.grid.sm.columns}, 1fr)`
    }
  };
  const mediaQueryMedium = {
    [theme.breakpoints.up('md')]: {
      padding: [[0, theme.grid.md.inset]],
      columnGap: theme.grid.md.gap,
      gridTemplateColumns: `repeat(${theme.grid.md.columns}, 1fr)`
    }
  };
  const mediaQueryLarge = {
    [theme.breakpoints.up('lg')]: {
      padding: [[0, theme.grid.lg.inset]],
      columnGap: theme.grid.lg.gap,
      gridTemplateColumns: `repeat(${theme.grid.lg.columns}, 1fr)`
    }
  };
  return {
    root: {
      display: 'grid',
      padding: [[0, theme.grid.xs.inset]],
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: theme.grid.xs.gap,
      rowGap: 0,
      gridTemplateColumns: `repeat(${theme.grid.xs.columns}, 1fr)`
    },
    maxWidthDefault: {
      ...mediaQuerySmall,
      ...mediaQueryMedium,
      ...mediaQueryLarge
    },
    maxWidthSm: {
      margin: [[0, 'auto']],
      maxWidth: theme.breakpoints.values.sm,
      ...mediaQuerySmall
    },
    maxWidthMd: {
      margin: [[0, 'auto']],
      maxWidth: theme.breakpoints.values.md,
      ...mediaQuerySmall,
      ...mediaQueryMedium
    },
    maxWidthLg: {
      margin: [[0, 'auto']],
      maxWidth: theme.breakpoints.values.lg,
      ...mediaQuerySmall,
      ...mediaQueryMedium,
      ...mediaQueryLarge
    }
  };
});

const Grid = props => {
  const { component, maxWidth, className, children, ...otherProps } = props;
  const classes = useStyles(props);
  const RootComponent = component ?? 'div';

  return (
    <RootComponent
      className={clsx(
        classes.root,
        [classes[`maxWidth${capitalize(maxWidth)}`]],
        className
      )}
      {...otherProps}
    >
      {children}
    </RootComponent>
  );
};

Grid.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOf(['default', 'lg', 'md', 'sm'])
};

Grid.defaultProps = {
  component: 'div',
  maxWidth: 'default'
};

export default Grid;
