import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from '../../../utils';
import { getLinkPropertiesForTarget } from '../Link/util';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.h5,
    display: 'block',
    width: '100%',
    borderRadius: 0,
    padding: theme.spacing(2),
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.text.light,
    '@media(hover:hover)': {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        '@media(hover:none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  sizeSmall: {
    ...theme.typography.body2,
    padding: [[theme.spacing(1), theme.spacing(2)]]
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    '& > span': {
      flexGrow: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    '&::before, &::after': {
      backgroundColor: theme.palette.common.white,
      content: '""',
      height: 1
    },
    '&::before': {
      flexGrow: 0,
      width: theme.spacing(1)
    },
    '&::after': {
      flexGrow: 1
    }
  }
}));

const MenuLink = props => {
  const { to, selected, size, children, className } = props;
  const classes = useStyles(props);

  return (
    <ButtonBase
      {...getLinkPropertiesForTarget(to)}
      className={clsx(
        classes.root,
        {
          [classes[`size${capitalize(size)}`]]: size !== 'default',
          [classes.selected]: selected
        },
        className
      )}
      disableRipple={false}
      disabled={selected}
    >
      {selected ? <span>{children}</span> : children}
    </ButtonBase>
  );
};

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'small']),
  className: PropTypes.string
};

MenuLink.defaultProps = {
  size: 'default',
  selected: false
};

export default MenuLink;
