import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuLink from '../../atoms/MenuLink';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from '../../../utils/index.js';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    backgroundColor: theme.palette.primary.dark
  },
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 2 * (320 + theme.spacing(1)),
      margin: '0 auto',
      '& > $list': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      },
      '& $itemLevel0': {
        flexGrow: 1,
        maxWidth: 320,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5)
      }
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 3 * (320 + theme.spacing(1))
    }
  },
  list: {
    padding: 0
  },
  item: {
    display: 'block',
    padding: 0
  },
  itemLevel0: {
    marginBottom: theme.spacing(3)
  },
  itemLevel1: {},
  colorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main
  },
  colorTertiary: {
    backgroundColor: theme.palette.tertiary.main
  }
}));

function NavigationListItem(props) {
  const { item, color, depth } = props;
  const classes = useStyles(props);
  const hasChildren = item.children && item.children.length > 0;

  return (
    <ListItem
      className={clsx(classes.item, classes[`itemLevel${depth}`])}
      disableGutters
    >
      <MenuLink
        color={color}
        selected={item.selected}
        size={depth > 0 ? 'small' : 'default'}
        to={item.to}
      >
        {item.title}
      </MenuLink>
      {hasChildren && (
        <NavigationList
          items={item.children}
          color={color}
          _depth={depth + 1}
        />
      )}
    </ListItem>
  );
}

NavigationListItem.propTypes = {
  depth: PropTypes.number,
  item: PropTypes.object,
  color: PropTypes.string
};

function NavigationList(props) {
  const { items, color, className, _depth = 0 } = props;
  const classes = useStyles(props);
  const isRoot = _depth === 0;

  if (_depth >= 2) {
    console.error(
      'exceeded max Navigation depth. Check your UI Pattern, so you do not need to deal with nested lists deeper than 3'
    );
    return '';
  }

  const list = (
    <List className={classes.list}>
      {items &&
        items.map((item, index) => (
          <NavigationListItem
            key={index}
            item={item}
            color={color}
            depth={_depth}
            classes={classes}
          />
        ))}
    </List>
  );
  return isRoot ? (
    <nav
      className={clsx(
        classes.root,
        {
          [classes[`color${capitalize(color)}`]]: color !== 'default'
        },
        className
      )}
    >
      <div className={classes.wrapper}>{list}</div>
    </nav>
  ) : (
    list
  );
}

NavigationList.defaultProps = {
  color: 'default'
};

NavigationList.propTypes = {
  items: PropTypes.array.isRequired,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary']),
  className: PropTypes.string,
  _depth: PropTypes.number
};

export default NavigationList;
