import remark from 'remark';
import html from 'remark-html';

/**
 * @param markdown string with markdown syntax @see https://daringfireball.net/projects/markdown/syntax
 * @param allowedTags array with allowd tags in target markup also @see https://unifiedjs.com/explore/package/hast-util-sanitize/
 *
 * @return string with html markup
 */
export const markdownToHtml = (markdown, allowedTags) => {
  if (markdown === '') {
    return '';
  }
  const sanitizeOptions = allowedTags
    ? { sanitize: { tagNames: allowedTags } }
    : null;

  return remark().use(html, sanitizeOptions).processSync(markdown).toString();
};

/**
 * @param htmlString string
 *
 * @return string given htmlString without any tags
 **/
export const stripTags = htmlString => {
  if (!htmlString || htmlString === '') {
    return '';
  }
  return htmlString.replace(/(<([^>]+)>)/gi, '').trim();
};

/**
 * @param orcidWorkNode {{url: string, externalIds: {}}} also see OrcidWork graphql scheme
 *
 * @return string calculated url representing url attribute or use external Ids for calculation
 **/
export const orcidWorkUrl = orcidWorkNode => {
  if (orcidWorkNode.url) {
    return orcidWorkNode.url;
  }

  const doiId = orcidWorkNode.externalIds.find(idObj => idObj.type === 'doi');
  if (doiId) {
    if (doiId.url) {
      return doiId.url;
    }

    if (doiId.value) {
      console.warn(
        `calculate unsafe urlFallback for OrcidWork path: ${orcidWorkNode.path}`
      );
      return `https://doi.org/${doiId.value}`;
    }
  }

  console.error(
    `unable to calculate fallback url for OrcidWork path: ${orcidWorkNode.path}`
  );
  return null;
};
