import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';

let theme = createMuiTheme(
  (function () {
    const inset = {
      xsmall: 8,
      small: 16,
      medium: 40,
      large: 56,
      xlarge: 104,
      xxlarge: 168
    };
    return {
      ...rem10pxHack625(),
      spacing: [0, 8, 16, 24, 40, 56, 72, 104, 136, 168, 232],
      grid: {
        xs: {
          columns: 4,
          gap: 16,
          inset: inset.small
        },
        sm: {
          columns: 6,
          gap: 16,
          inset: inset.small
        },
        md: {
          columns: 12,
          gap: 24,
          inset: inset.medium
        },
        lg: {
          columns: 12,
          gap: 24,
          inset: inset.medium
        }
      },
      inset,
      stack: {
        xsmall: 8,
        small: 16,
        medium: 40,
        large: 56,
        xlarge: 104
      },
      inline: {
        xsmall: 8,
        small: 16,
        medium: 40,
        large: 56,
        xlarge: 104
      }
    };
  })()
);

theme = typography(theme);
theme = overrides(theme);
theme = colorPalette(theme);

/**
 * rem simplification Hack @see https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
 *
 * @param {Object} theme MuiTheme
 * @returns {Object} theme with hack applied
 */
function rem10pxHack625() {
  return {
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            fontSize: '62.5%' /* 62.5% of 16px = 10px */
          }
        }
      }
    },
    typography: {
      htmlFontSize: 10
    }
  };
}

function overrides(theme) {
  theme.overrides = {
    ...theme.overrides,
    MuiCssBaseline: {
      ...theme.overrides.MuiCssBaseline,
      '@global': {
        ...theme.overrides.MuiCssBaseline['@global'],
        html: {
          ...theme.overrides.MuiCssBaseline['@global'].html,
          textSizeAdjust: 'none'
        },
        body: {
          ...theme.overrides.MuiCssBaseline['@global'].body,
          overflowX: 'hidden'
        }
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: theme.grid.xs.inset,
        paddingRight: theme.grid.xs.inset,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.grid.sm.inset,
          paddingRight: theme.grid.sm.inset
        },
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.grid.md.inset,
          paddingRight: theme.grid.md.inset
        },
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.grid.lg.inset,
          paddingRight: theme.grid.lg.inset
        }
      }
    }
  };
  return theme;
}

function colorPalette(theme) {
  theme.palette = {
    ...theme.palette,
    primary: palette.skyblue,
    secondary: palette.grassgreen,
    // TODO remove tertiary when not used in components anymore
    tertiary: palette.brightorange,
    text: {
      main: '#104360',
      mainLowContrast: 'rgb(16, 67, 96, 0.4)',
      headline: '#1F7DB2',
      dark: 'rgb(0, 0, 0, 0.87)',
      darkLowContrast: 'rgb(0, 0, 0, 0.70)',
      light: '#fff',
      lightLowContrast: 'rgb(255, 255, 255, 0.90)'
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121'
    }
  };
  // TODO remove when not used in components anymore
  // delete theme.palette.common;
  return theme;
}

function typography(theme) {
  theme.typography = {
    ...theme.typography,
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '1,4rem',
    h1: {
      ...theme.typography.h1,
      fontSize: '3.6rem',
      fontStyle: 'normal',
      lineHeight: `${44 / 36}`,
      fontWeight: 300,
      letterSpacing: `${-1 / 36}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '7.2rem',
        lineHeight: `${88 / 72}`,
        letterSpacing: `${-1 / 72}em`
      }
    },
    h2: {
      ...theme.typography.h2,
      fontSize: '2.8rem',
      lineHeight: `${36 / 28}`,
      fontStyle: 'normal',
      fontWeight: 300,
      letterSpacing: `${-0.5 / 28}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '5.6rem',
        lineHeight: `${72 / 56}`,
        letterSpacing: 0
      }
    },
    h3: {
      ...theme.typography.h3,
      fontSize: '2.4rem',
      lineHeight: `${30 / 24}`,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '4.0rem',
        lineHeight: `${56 / 40}`
      }
    },
    h4: {
      ...theme.typography.h4,
      fontSize: '2.0rem',
      lineHeight: `${24 / 20}`,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: `${0.25 / 20}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '3.4rem',
        lineHeight: `${48 / 34}`,
        letterSpacing: `${0.25 / 34}em`
      }
    },
    h5: {
      ...theme.typography.h5,
      fontSize: '1.8rem',
      lineHeight: `${22 / 18}`,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: `${0.25 / 18}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem',
        lineHeight: `${28 / 24}`,
        letterSpacing: `${0.25 / 24}em`
      }
    },
    h6: {
      ...theme.typography.h6,
      fontSize: '1.6rem',
      lineHeight: `${20 / 16}`,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: `${0.15 / 16}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.0rem',
        lineHeight: `${26 / 20}`,
        letterSpacing: `${0.15 / 20}em`
      }
    },
    body1: {
      ...theme.typography.body1,
      fontSize: '1.6rem',
      fontStyle: 'normal',
      lineHeight: `${24 / 16}`,
      fontWeight: 400,
      letterSpacing: `${0.25 / 16}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.8rem',
        fontStyle: 'normal',
        lineHeight: `${28 / 18}`,
        letterSpacing: `${0.5 / 18}em`
      }
    },
    body2: {
      ...theme.typography.body2,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: `${20 / 14}`,
      fontWeight: 400,
      letterSpacing: `${0.25 / 14}em`,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.6rem',
        fontStyle: 'normal',
        lineHeight: `${24 / 16}`,
        fontWeight: 400,
        letterSpacing: `${0.25 / 16}em`
      }
    },
    subtitle1: {
      ...theme.typography.subtitle1,
      fontSize: '2.4rem',
      lineHeight: `${30 / 24}`,
      fontStyle: 'normal',
      fontWeight: 300,
      letterSpacing: 0,
      [theme.breakpoints.up('md')]: {
        fontSize: '4.0rem',
        lineHeight: `${56 / 40}`
      }
    },
    subtitle2: {
      ...theme.typography.subtitle2,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: `${24 / 14}`,
      fontWeight: 400,
      letterSpacing: 0
    },
    button: {
      ...theme.typography.button,
      fontSize: '1.4rem',
      fontStyle: 'normal',
      lineHeight: `${18 / 14}`,
      fontWeight: 500,
      letterSpacing: `${0.75 / 14}em`,
      textTransform: 'uppercase'
    },
    caption: {
      ...theme.typography.caption,
      fontSize: '1.2rem',
      fontStyle: 'normal',
      lineHeight: `${16 / 12}`,
      fontWeight: 400,
      letterSpacing: `${0.4 / 12}em`
    },
    overline: {
      ...theme.typography.overline,
      fontSize: '1.0rem',
      fontStyle: 'normal',
      lineHeight: `${16 / 10}`,
      fontWeight: 500,
      letterSpacing: `${1.5 / 10}em`,
      textTransform: 'uppercase'
    }
  };
  return theme;
}

export default theme;
