export function capitalize(string) {
  if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
    throw new Error(
      'Advanced-Chemistry-UI: capitalize(string) expects a string argument.'
    );
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shorten(str, maxLen, separator = ' ', postfix = '...') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + postfix;
}

export function* range(start, end) {
  yield start;
  if (start === end) return;
  yield* range(start + 1, end);
}

export function stripTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}

export function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

export function dummyImage(props) {
  const { width, height, format = 'png' } = props;
  const base = `https://via.placeholder.com/${width}x${height}/#cccccc/#ffffff`;
  return format ? `${base}.${format}` : base;
}
