import React from 'react';
import PropTypes from 'prop-types';
import MaterialLink from '@material-ui/core/Link';
import IconEast from '../../base/Icons/EastRounded';
import IconNorthEast from '../../base/Icons/NorthEastRounded';
import IconOrcid from '../../base/Icons/Orcid';
import IconSaveAlt from '../../base/Icons/SaveAlt';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from '../../../utils';
import { isInternalTarget, getLinkPropertiesForTarget } from './util';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    '&$colorDefault': {
      color: theme.palette.text.main,
      '& $iconPost': {
        color: theme.palette.primary.main
      },
      '@media(hover:hover)': {
        '&:hover $iconPost, &:hover $iconPre': {
          color: theme.palette.text.main
        }
      }
    },
    '&$colorOnDark': {
      color: theme.palette.text.light,
      '& $iconPost': {
        color: theme.palette.primary.light
      },
      '@media(hover:hover)': {
        '&:hover $iconPost, &:hover $iconPre': {
          color: theme.palette.text.light
        }
      }
    },
    '& $iconPre': {
      color: theme.palette.primary.main,
      marginRight: 4,
      position: 'relative',
      top: 6
    },
    '& $iconPost': {
      color: theme.palette.primary.main,
      marginLeft: 4,
      position: 'relative',
      top: 6
    },
    '& $iconOrcid': {
      color: '#A6CE39'
    },
    '& $variantExternal': {
      '& $iconPost': {
        transform: 'rotate(-45deg)'
      }
    }
  },
  colorDefault: {},
  colorOnDark: {},
  iconPost: {},
  iconPre: {},
  iconOrcid: {},
  variantInternal: {},
  variantExternal: {}
}));

const Link = props => {
  const { variant, children, color, className, to, ...otherProps } = props;
  const additionalColors = ['default', 'onDark'];
  const isOverrideColor = additionalColors.some(item => item === color);
  const classes = useStyles(props);
  let processedVariant = variant;
  if (variant === 'dynamic') {
    processedVariant = isInternalTarget(to) ? 'internal' : 'external';
  }
  if (processedVariant === 'external') {
    otherProps.target = otherProps.target ?? '_blank';
  }
  const iconMap = {
    internal: IconEast,
    external: IconNorthEast,
    orcid: IconOrcid,
    download: IconSaveAlt
  };
  const renderPostIcon = () => {
    if (!['internal', 'external'].some(v => v === processedVariant)) {
      return;
    }
    const IconComponent = iconMap[processedVariant];
    return <IconComponent className={classes.iconPost} fontSize="small" />;
  };
  const renderPreIcon = () => {
    if (!['orcid', 'download'].some(v => v === processedVariant)) {
      return;
    }
    const IconComponent = iconMap[processedVariant];
    return (
      <IconComponent
        className={clsx(classes.iconPre, {
          [classes.iconOrcid]: processedVariant === 'orcid'
        })}
        fontSize="small"
      />
    );
  };

  return (
    <MaterialLink
      color={isOverrideColor ? 'initial' : color}
      {...getLinkPropertiesForTarget(to)}
      variant={variant === 'service' ? 'body1' : 'button'}
      className={clsx(
        classes.root,
        {
          [classes[`color${capitalize(color)}`]]: isOverrideColor,
          [classes[`variant${capitalize(processedVariant)}`]]:
            processedVariant !== 'default'
        },
        className
      )}
      underline="hover"
      {...otherProps}
    >
      {renderPreIcon()}
      {children}
      {renderPostIcon()}
    </MaterialLink>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.oneOf([
    'default',
    'internal',
    'external',
    'dynamic',
    'orcid',
    'download',
    'service'
  ]),
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'tertiary',
    'error',
    'inherit',
    'textPrimary',
    'textSecondary',
    'onDark'
  ]),
  className: PropTypes.string
};

Link.defaultProps = {
  color: 'default',
  variant: 'default'
};

export default Link;
