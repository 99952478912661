import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import MenuButton from '../../atoms/MenuButton';
import NavigationList from '../../molecules/NavigationList';
import { Link as GatsbyLink } from 'gatsby';
import Logo from '../../atoms/Logo';
import clsx from 'clsx';
import { capitalize } from '../../../utils/helpers';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: '100%',
    margin: 0,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  drawerModal: {
    top: theme.spacing(6)
  },
  logo: {
    display: 'block',
    margin: [[theme.spacing(2), 0, 0, theme.spacing(2)]],
    [theme.breakpoints.up('md')]: {
      margin: [[theme.spacing(4), 0, 0, theme.spacing(5)]]
    }
  },
  navigationList: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(8)
    }
  },
  menuButton: {
    zIndex: theme.zIndex.modal + 1
  },
  menuButtonIsActive: {
    transition: [['background-color', '0.2s', 'ease-out']],
    backgroundColor: 'transparent'
  },
  positionAbsolute: {
    position: 'absolute',
    left: 'auto',
    right: 0,
    top: 0
  },
  positionFixed: {
    position: 'fixed',
    left: 'auto',
    right: 0,
    top: 0
  },
  positionSticky: {
    position: 'sticky',
    left: 'auto',
    right: 0,
    top: 0
  },
  positionRelative: {
    position: 'relative'
  }
}));

const MainMenu = props => {
  const { items, onOpen, onClose, open, position, ...others } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(open);
  const classes = useStyles(props);

  function toggleDrawerOpen() {
    if (!isDrawerOpen) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
    }
    setIsDrawerOpen(!isDrawerOpen);
  }

  function setDrawerClosed() {
    setIsDrawerOpen(false);
  }

  return (
    <React.Fragment>
      <MenuButton
        classes={{
          root: clsx(
            classes.menuButton,
            classes[`position${capitalize(position)}`]
          ),
          isActive: classes.menuButtonIsActive
        }}
        isActive={isDrawerOpen}
        onClick={toggleDrawerOpen}
        {...others}
      />
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={setDrawerClosed}
        classes={{ paper: classes.drawerPaper, modal: classes.drawerModal }}
      >
        <Logo
          component={GatsbyLink}
          to="/"
          className={classes.logo}
          onLight={false}
        />
        <NavigationList className={classes.navigationList} items={items} />
      </Drawer>
    </React.Fragment>
  );
};

MainMenu.defaultProps = {
  open: false,
  position: 'absolute'
};

MainMenu.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onPaperIsScrolled: PropTypes.func,
  classes: PropTypes.object,
  position: PropTypes.oneOf(['relative', 'fixed', 'absolute', 'sticky'])
};

export default MainMenu;
