import { graphql } from 'gatsby';
import { stripTrailingSlash } from '../../../utils/helpers.js';

function equalPaths(first, second) {
  return stripTrailingSlash(first) === stripTrailingSlash(second);
}

function navigationItems(pageItemsData, currentPath) {
  return pageItemsData.map(edge => {
    const node = edge.node || {};
    const slug = node.fields.slug;

    return {
      title: node.frontmatter.intro.heading,
      to: slug,
      selected: equalPaths(currentPath, slug)
    };
  });
}

export function mapToProps(data, location = { pathname: 'not/defined' }) {
  const currentPath = location.pathname;
  const researchArticlesData = data?.topbar_researchArticles?.edges ?? [];
  const labArticlesData = data?.topbar_labArticles?.edges ?? [];
  const teamMembersData = data?.topbar_teamMembers?.edges ?? [];

  return {
    menueItems: [
      {
        title: 'Who We Are',
        to: '/team/',
        selected: equalPaths(currentPath, '/team'),
        children: navigationItems(teamMembersData, currentPath)
      },
      {
        title: 'Research',
        to: '/research/',
        selected: equalPaths(currentPath, '/research'),
        children: navigationItems(researchArticlesData, currentPath)
      },
      {
        title: 'Publications',
        to: '/publications/',
        selected: equalPaths(currentPath, '/publications')
      },
      {
        title: 'Laboratory',
        to: '/laboratory/',
        selected: equalPaths(currentPath, '/laboratory'),
        children: navigationItems(labArticlesData, currentPath)
      },
      {
        title: 'Contact',
        to: '/contact/',
        selected: equalPaths(currentPath, '/contact')
      },
      {
        title: 'Legal',
        to: '/legal/',
        selected: equalPaths(currentPath, '/legal')
      }
    ]
  };
}

export const fragment = graphql`
  fragment TopBar on Query {
    topbar_researchArticles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "researchArticlePage" } } }
      sort: { fields: frontmatter___position, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            intro {
              heading
            }
          }
        }
      }
    }
    topbar_labArticles: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "labArticlePage" } } }
      sort: { fields: frontmatter___position, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            intro {
              heading
            }
          }
        }
      }
    }
    topbar_teamMembers: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "teamPage" } } }
      sort: { fields: frontmatter___position, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            intro {
              heading
            }
          }
        }
      }
    }
  }
`;
