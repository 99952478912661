import React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const Meta = props => {
  return (
    <GatsbySeo
      metaTags={[
        {
          name: 'google-site-verification',
          content: '6MiAMb4p3_cCcC-MlKEGeFPdJ8nqEWGM8NPQv5NOoLs'
        },
        {
          name: 'msvalidate.01',
          content: '85B08D1F403F9E1FE04CBC134E624473'
        }
      ]}
      linkTags={[
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/img/favicon/apple-touch-icon.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/img/favicon/favicon-32x32.png'
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/img/favicon/favicon-16x16.png'
        },
        {
          rel: 'icon',
          href: '/img/favicon/favicon.ico'
        }
      ]}
      {...props}
    />
  );
};

export default Meta;
