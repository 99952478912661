import React from 'react';
import createIcon from '../../../utils/createIcon';

export default createIcon(
  <>
    <path d="M11.7688 14.7752H13.3001C15.4813 14.7752 15.9813 13.1189 15.9813 12.2939C15.9813 10.9502 15.1251 9.81267 13.2501 9.81267H11.7688V14.7752Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4188 20 20 16.4188 20 12C20 7.58125 16.4188 4 12 4C7.58125 4 4 7.58125 4 12C4 16.4188 7.58125 20 12 20ZM8.43134 15.6377H9.39384V8.94392H8.43134V15.6377ZM10.8063 8.94392H13.4063C15.8813 8.94392 16.9688 10.7127 16.9688 12.2939C16.9688 14.0127 15.6251 15.6439 13.4188 15.6439H10.8063V8.94392ZM8.9125 8.18145C9.2625 8.18145 9.54375 7.89395 9.54375 7.5502C9.54375 7.20645 9.2625 6.91895 8.9125 6.91895C8.5625 6.91895 8.28125 7.2002 8.28125 7.5502C8.28125 7.89395 8.5625 8.18145 8.9125 8.18145Z"
    />
  </>,
  'Orcid'
);
