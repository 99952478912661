import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../atoms/Logo';
import { Link as GatsbyLink } from 'gatsby';
import Link from '../../atoms/Link';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    '& $contentWrapper': {
      backgroundColor: theme.palette.text.main,
      padding: [[theme.inset.medium, theme.inset.small, theme.inset.small]]
    },
    '& $logo': {
      marginBottom: theme.stack.small
    },
    '& $serviceLinksLink': {
      display: 'inline-block',
      width: 'auto',
      marginRight: theme.inline.medium,
      '&:last-child': {
        marginRight: 0
      }
    },
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.palette.secondary.main,
      height: theme.stack.large
    },
    [theme.breakpoints.up('md')]: {
      '& $contentWrapper': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.inset.large
      },
      '& $logo': {
        marginBottom: 0
      }
    }
  },
  contentWrapper: {},
  logo: {},
  serviceLinks: {},
  serviceLinksLink: {}
}));

const Footer = props => {
  const { serviceLinks, className } = props;
  const classes = useStyles(props);

  return (
    <footer className={clsx(classes.root, className)}>
      <div className={classes.contentWrapper}>
        <Logo
          component={GatsbyLink}
          to="/"
          className={classes.logo}
          onLight={false}
        />
        <div className={classes.serviceLinks}>
          {serviceLinks.map((linkProps, index) => (
            <Link
              variant="service"
              color="onDark"
              className={classes.serviceLinksLink}
              key={index}
              {...linkProps}
            />
          ))}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  serviceLinks: PropTypes.arrayOf(PropTypes.shape(Link.propTypes)).isRequired,
  className: PropTypes.string
};

export default Footer;
