import React from 'react';
import withCssBaseline from './withCssBaseLine';
import withTheme from './withTheme';

const withRoot = Component => props => {
  const Enhanced = withTheme(withCssBaseline(Component));
  return <Enhanced {...props} />;
};

export default withRoot;
